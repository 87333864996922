<template>
  <span
    @click.prevent="togglePlayingAudio()"
    class="form__file-toggle-play"
    :class="{'form__file-toggle-play_active': isPlaying}"
  >
    <img v-if="!isPlaying" src="@/assets/images/play-circle-regular.svg" alt="">
    <img v-else src="@/assets/images/stop-circle-regular.svg" alt="">
  </span>
</template>

<script>
  export default {
    name: 'AudioComponent',
    props: {
      file: {
        default: {
          file: {
            data: new ArrayBuffer()
          },
          metadata: {}
        },
        type: Object
      }
    },
    data() {
      return {
        isPlaying: false,
        audio: null,
      }
    },
    mounted() {
      this.audio = document.createElement('audio');
      this.audio.src = window.URL.createObjectURL(new Blob([this.file.file.data], this.file.file.metadata));
      this.audio.onpause = () => this.onPause()
      // this.audio.onended = () => console.log('ended')
    },
    methods: {
      onPause() {
        this.isPlaying = false
      },
      togglePlayingAudio() {
        if (this.audio.paused) {
          this.audio.play();
          this.isPlaying = true
        } else {
          this.audio.pause();
        }

      }
    }

  }
</script>