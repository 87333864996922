<template>
  <div class="container container_note">
    <div class="form">
      <div
        class="window window_note"
        @dragenter.prevent.stop="dragging = true"
        @dragover.prevent.stop="dragging = true"
      >
        <div class="form__wrapper-img">

          <div class="form__top">
            <div class="textarea__wrapper">

              <a
                :class="!currentMessage ? 'form__if-one-image_full' : null"
                v-if="!noteLoading && files.length == 1 && file.isImage"
                v-for="file in files"
                class="form__if-one-image"
                :href="getBlobFileUrl(file)"
                target="_blank"
                :download="getFileDownloadName(file)"
              >

                <img
                  class="form__if-one-image__image"
                  :class="!isNoteOpened ? 'form__if-one-image__image_blur' : null"
                  :src="file.imageData" alt="attachment">

                <img class="form__if-one-image__download" src="@/assets/images/button-download.svg" alt="download">
              </a>

              <CompiledHtml
                v-if="!noteLoading"
                :text="currentMessage"
              />

              <div v-else>
                <b-spinner variant="primary"></b-spinner>
              </div>

              <div class="form__file-list" v-if="isNoteOpened">
                <a
                  v-for="file in files"
                  class="form__filename"
                  :href="getBlobFileUrl(file)"
                  target="_blank"
                  :download="getFileDownloadName(file)"
                  v-if="files.length > 1 || !file.isImage"
                >
                  <span class="form__file-control-wrapper">
                    <FileIcon v-if="!file.isAudio" :file="file" :class="{'d-none': file.isPlaying}"/>
                    <AudioComponent v-else :file="file"/>
                  </span>

                  <span class="form__filename-title">
                {{file.file.metadata.name}}
              </span>
                </a>
              </div>

              <div class="form__file-list" v-else-if="fileStatuses.length">
                <button
                  v-for="filename in fileStatuses"
                  class="form__filename"
                >
                  <span class="form__filename-icon-wrapper">
                    <img class="form__filename-icon" src="@/assets/images/attachment.svg" alt="attachment">
                  </span>
                  {{filename}}
                </button>
              </div>
            </div>

            <div class="note-actions note-actions_right-bar">
              <button
                v-if="isNoteOpened && this.noteData && this.noteData.message"
                class="button__note-action"
                @click="copyNote"
              >
                <img src="../../assets/images/copy.svg" alt="copy">
              </button>

              <button
                class="button__note-action"
                @click="onClickToggleNote"
              >
                <img src="@/assets/images/eye.svg" alt="eye">
              </button>

            </div>

          </div>
        </div>

        <div class="form__bottom form__bottom_note">

          <div class="form__bottom-right form__bottom-right_desktop">

            <b-modal
              centered
              ref="modal-password"
              modal-class="modal-password"
              hide-header
              hide-footer
              no-close-on-backdrop
              no-close-on-esc
              id="modal-password"
              :visible="isShowPasswordModal"
            >
              <form
                id="field-password-input-wrapper"
                class="modal-field__password modal-field__password_disabled"
                @submit.prevent="onPasswordEnter"
                :class="{'modal-field__password_error' : notePasswordError}"
              >
                <span class="modal-field__password-icon"><img src="@/assets/images/lock.svg" alt="lock"></span>
                <input
                  id="field-password-input"
                  class="modal-field__password__input"
                  type="text"
                  v-model="notePassword"
                  placeholder="Enter password"
                  :disabled="isNoteOpened"
                />
                <button class="modal-field__password-submit" type="submit"><img
                  src="@/assets/images/arrow-right_purple.svg" alt="submit"></button>
              </form>
              <div v-if="isShowErrorText" class="modal-field__password-error">
                {{errorText}}
              </div>
            </b-modal>
          </div>

          <div class="form__bottom-left_mobile">

            <button
              class="button__note-action button__attachment"
              @click="onClickToggleNote"
            >
              <img src="@/assets/images/eye.svg" alt="eye">
            </button>

            <button
              v-if="isNoteOpened && this.noteData && this.noteData.message"
              class="button__note-action button__attachment button__attachment_copy"
              @click="copyNote"
            >
              <img src="@/assets/images/copy.svg" alt="copy">
            </button>
          </div>

        </div>

        <div v-if="isShowErrorText" class="form__bottom-error">
          {{errorText}}
        </div>

        <div
          v-if="dragging"
          class="drag-overlay"
          @drop.prevent="onDropFile"
          @dragleave="dragging=false"
        >
          Drop files here to upload
        </div>
      </div>
      <button @click="createNewNote" class="button__submit" type="submit">
        Create a new note
      </button>
      <button
        @click="replyNote"
        class="button__submit button__submit_transparent"
        type="submit"
        :disabled="!canReply"
      >
        Reply
      </button>
      <button
        @click="forwardNote"
        class="button__submit button__submit_transparent"
        type="submit"
        :disabled="!canForward">
        Forward
      </button>

      <b-modal
        id="confirm-modal"
        ref="confirmModal"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        hide-header
        hide-header-close
        centered
        :visible="isVisibleConfirmModal"
        body-class="note-confirm-modal__body"
        dialog-class="note-confirm-modal__dialog"
        modal-class="note-confirm-modal modal-note"
      >

        <div
          v-if="noteStatusLoading"
          class="note-confirm-modal__content"
        >
          <b-spinner variant="primary"></b-spinner>
        </div>

        <div
          v-else-if="noteStatusData && noteStatusData.hasBurned"
          class="note-confirm-modal__content"
        >
          <div class="note-confirm-modal__actions">
            <button
              class="note-confirm-modal__button note-confirm-modal__read-button button__submit"
              @click="createNewNote"
            >
              Create new note
            </button>
          </div>
        </div>

        <div
          v-else-if="noteStatusError"
          class="note-confirm-modal__content"
        >
          <div class="note-confirm-modal__header">
            {{noteStatusError}}
          </div>

          <div class="note-confirm-modal__actions">
            <button
              class="note-confirm-modal__button note-confirm-modal__read-button button__submit"
              @click="createNewNote"
            >
              Create new note
            </button>
          </div>
        </div>

        <div
          v-else
          class="note-confirm-modal__content"
        >
          <div class="note-confirm-modal__header">The note will be deleted after reading</div>
          <div class="note-confirm-modal__actions">
            <button
              class="note-confirm-modal__button note-confirm-modal__read-button button__submit"
              @click="onReadNow"
            >
              Read now
            </button>
            <button
              class="note-confirm-modal__button note-confirm-modal__later-button"
              @click="onReadLater"
            >
              Later
            </button>
          </div>
        </div>

      </b-modal>

      <b-modal
        id="mobile-menu-modal"
        ref="mobileMenuModal"
        hide-footer
        hide-header
        centered
        :visible="isVisibleMobileSettingsModal"
        body-class="note-confirm-modal__body"
        dialog-class="note-confirm-modal__dialog"
        modal-class="note-confirm-modal modal-settings"
      >

        <div class="modal__wrapper">
          <h2 class="modal__wrapper-title">Note settings</h2>
          <b-button class="modal__close" @click="closeMobileNoteSettings">
            <img src="../../assets/images/cross.svg" alt="close">
          </b-button>
        </div>

        <div class="field__password">
          <span class="field__password-icon"><img src="@/assets/images/lock-purple.svg" alt="lock"></span>
          <input
            class="field__password__input"
            type="text"
            v-model="notePassword"
            placeholder="Enter password"
          />
        </div>
      </b-modal>

    </div>

  </div>
</template>

<script>
  import {
    getNote,
    getNoteStatus, getTTLList, validateEmail,
  } from '../../utils'
  import axios from 'axios'
  import * as WAValidator from "wallet-address-validator";
  import CompiledHtml from "../partials/CompiledHtml";
  import APIProvider from "@/providers/APIProvider";
  import FileIcon from "@/views/partials/FileIcon";
  import AudioComponent from "@/views/partials/AudioComponent";

  export default {
    name: 'Note',
    components: {
      CompiledHtml,
      FileIcon,
      AudioComponent,
    },
    data() {
      return {
        message: '',
        noteId: '',
        noteHash: '',
        noteLoading: false,
        noteData: null,
        noteDataEncrypted: null,
        noteError: null,
        noteStatusData: null,
        noteStatusError: null,
        noteStatusLoading: true,
        notePassword: '',
        ttlList: getTTLList(),
        isVisibleConfirmModal: true,
        isShowPasswordModal: false,
        isNoteOpened: false,
        isShowPasswordPopover: false,
        errorText: '',
        isShowErrorText: false,
        notePasswordError: false,
        isVisibleMobileSettingsModal: false,
        dragging: false,
        shareCapability: navigator && !!navigator.canShare || false,
      }
    },
    mounted() {

      // this.noteId = this.$route.params.noteId
      // this.noteHash = this.$route.hash ? this.$route.hash.replace('#', '') : ''
      // this.notePassword = this.noteHash
      this.noteId = localStorage.getItem('wipenote-get-note_id')
      this.notePassword = localStorage.getItem('wipenote-get-note_pwd')
      this.noteHash = this.notePassword

      this.resetUrl()
      this.loadNoteStatus()
    },
    computed: {
      currentMessage() {
        const statusData = this.noteStatusData

        if (this.isNoteOpened) {
          return this.noteData && this.noteData.message
        }

        if (!statusData || !statusData.messageLength) {
          return ''
        }

        return new Array(statusData.messageLength).fill('*').join('')
      },
      currentTTLLabel() {
        if (!this.noteStatusData || !this.noteStatusData.burnDate) {
          return ''
        }

        const ttlItem = this.ttlList.find(ttl => ttl.value === this.noteStatusData.burnDate)

        return ttlItem && ttlItem.label
      },
      fileStatuses() {
        return this.noteStatusData && this.noteStatusData.files ?
          new Array(this.noteStatusData.files).fill('***********')
          : []
      },
      files() {
        const files = this.noteData && this.noteData.files || []
        return files.map(file => ({
          ...file,
          isAudio: file.file.metadata.type.startsWith('audio/')
        }))
      },
      canReply() {
        return this.isNoteOpened && this.noteData && this.noteData.message
      },
      canForward() {
        if (!this.noteData) return false
        const {files, message} = this.noteData
        const hasFiles = Array.isArray(files) && files.length
        return this.isNoteOpened && (hasFiles || message)
      },
    },
    methods: {
      resetUrl() {
        if (window && window.history) {
          window.history.replaceState({}, 'home', '/')
        }
      },
      getRecognizedWordHtml(word) {
        if (validateEmail(word)) {
          return `<a class="link" href="mailto:${word}">${word}</a>`
        }

        if (WAValidator.validate(word, 'BTC')) {
          return `<a class="link_bitcoin" href="bitcoin:${word}">${word}</a>`
        }

        return `<span>${word}</span>`
      },
      async onClickToggleNote() {
        if (this.isNoteOpened) {
          this.isNoteOpened = false
        } else {
          await this.showNote()
          this.isNoteOpened = true
        }
      },
      hideConfirmModal() {
        this.isVisibleConfirmModal = false
      },
      createNewNote() {
        this.$router.push({name: 'home'})
      },
      async loadNoteStatus() {
        this.noteStatusLoading = true

        try {
          // console.log('getNoteStatus', this.noteId)
          const status = await getNoteStatus({id: this.noteId})
          // console.log('getNoteStatus', status)
          this.noteStatusData = status
        } catch (e) {
          // console.error('loadNoteStatus', e)
          console.dir(e)
          this.noteStatusError = e.response && e.response.data && e.response.data.message || e.message
        }

        this.noteStatusLoading = false

      },
      async loadNote() {
        let hash

        if (this.noteHash) {
          hash = this.noteHash
        } else {
          hash = this.notePassword
        }

        if (!hash) {
          this.showPasswordModal()
          throw new Error('Password was not specified')
        }
        this.noteLoading = true

        try {
          console.log('urlencodedKey', hash)
          if (!this.noteDataEncrypted) {
            const response = await APIProvider.getNote(this.noteId)
            this.noteDataEncrypted = response.data
          }
          this.noteData = await getNote({
            password: hash,
            encryptedNote: this.noteDataEncrypted
          })
          console.log('getnote', this.noteData)
        } catch (e) {
          this.noteLoading = false
          console.error(e.message)
          this.showPageError(e.message)
          this.isShowPasswordModal = true
          throw e
        }

        this.noteLoading = false
      },
      showPasswordModal() {
        this.isShowPasswordModal = true
      },
      closeShowPasswordModal() {
        this.isShowPasswordModal = false
      },
      async onReadNow() {
        this.$refs.confirmModal.hide()
        if (this.noteHash) {
          await this.loadNote()
          this.isNoteOpened = true
        } else {
          this.showPasswordModal()
        }

      },
      onReadLater() {
        // this.$router.push({name: 'home'})
        this.hideConfirmModal()
      },
      downloadFile(file) {
        // var blob = new Blob([file.data], file.metadata);
        // var objectUrl = URL.createObjectURL(blob);
        // window.open(objectUrl);

        const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        link.href = file.imageData;
        link.download = file.file.metadata.name
        link.click();
      },
      getBlobFileUrl(file) {
        let blob = new Blob([file.file.data], file.file.metadata);
        let objectUrl

        if (window.webkitURL) {
          objectUrl = window.webkitURL.createObjectURL(blob);
        } else {
          objectUrl = window.URL.createObjectURL(blob);
        }

        return objectUrl
      },
      getFileDownloadName(file) {
        return file && file.file && file.file.metadata && file.file.metadata.name
      },
      viewFile(file) {
        let blob = new Blob([file.file.data], file.file.metadata);
        let objectUrl

        if (window.webkitURL) {
          objectUrl = window.webkitURL.createObjectURL(blob);
        } else {
          objectUrl = window.URL.createObjectURL(blob);
        }
        // const anchor = document.createElement('a');
        // anchor.href = objectUrl;
        // anchor.target = '_blank';
        // anchor.click();
        // console.log('viewfile', file, 'blob', blob, 'object', objectUrl, 'anchor', anchor)

        // console.log('view view objecturl', objectUrl)
        // window.open(objectUrl, '_blank')
        // link.href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = file.file.metadata.name
        link.target = '_blank';

        document.body.appendChild(link);
        link.style = "display: none";
        link.click();
        document.body.removeChild(link);
      },
      async showNote() {
        this.closeShowPasswordModal()
        this.hidePageError()

        if (this.noteLoading) {
          return
        }
        if (!this.noteData) {
          await this.loadNote()
        }
      },
      async onPasswordEnter() {
        if (!this.isNoteOpened) {
          await this.showNote()
          this.isNoteOpened = true
        }
      },
      showPageError(text) {
        this.errorText = text
        this.isShowErrorText = true
        this.notePasswordError = true
      },
      hidePageError() {
        this.isShowErrorText = false
      },
      openMobileNoteSettings() {
        this.isVisibleMobileSettingsModal = true
      },
      closeMobileNoteSettings() {
        this.isVisibleMobileSettingsModal = false
      },
      toggleNoteVisibility() {
        this.isNoteVisible = !this.isNoteVisible
      },
      replyNote() {
        if (this.isNoteOpened) {
          let newMessage = this.noteData.message
            .split('\n')
            .map(line => `> ${line}`)
            .join('\n')

          newMessage += '\n'

          this.$router.push({
            name: 'home',
            params: {replyMessage: newMessage}
          })

        }
      },
      forwardNote() {
        if (this.isNoteOpened) {
          let newMessage = this.noteData.message
            .split('\n')
            .map(line => `> ${line}`)
            .join('\n')

          newMessage += '\n'
          const filesList = this.noteData.files.map(({file}) => {
            return new File([file.data], file.metadata.name, {type: file.metadata.type});
          })

          this.$router.push({
            name: 'home',
            params: {
              replyMessage: newMessage,
              files: filesList,
            }
          })

        }
      },
      copyNote() {
        this.$clipboard(this.currentMessage)
        this.$bvToast.toast('Note has been copied', {
          // title: `Copied successfully`,
          variant: 'success',
          solid: true,
          autoHideDelay: 3000,
        })
      },
      onDropFile(e) {
        this.dragging = false
        let newMessage = this.noteData.message
          .split('\n')
          .map(line => `> ${line}`)
          .join('\n')

        newMessage += '\n'

        this.$router.push({
          name: 'home',
          params: {
            replyMessage: newMessage,
            dropFilesEvent: e,
          }
        })
      },
      shareNote() {
        try {
          const filesList = this.noteData.files.map(({file}) => {
            return new File([file.data], file.metadata.name, {type: file.metadata.type});
          })

          const textCopyright = `\r\n\r\n${window.location.origin}`
          const shareData = {
            files: filesList,
            title: `Note ${this.noteId}| Wipenote`,
            text: `${this.currentMessage}${textCopyright}`,
            url: window.location.origin,
          }

          console.log('shareData', shareData)

          if (navigator.canShare && navigator.canShare(shareData)) {
            navigator.share(shareData)
          }
          console.log('share files success')
        } catch (e) {
          console.error('share files error', e)
        }
      },
      isAudioFile(file) {
        if (!(file && file.file && file.file.metadata)) return false
        return file.file.metadata.type.startWith('audio/')
      },
      togglePlayingAudio(targetFile) {
        this.noteData.files = this.noteData.files.map(file => {
          if (file === targetFile) {
            file.isPlaying = !file.isPlaying
          }
          return file
        })

        if (!targetFile.isPlaying) {

        }

      }
    }
  }
</script>